import React, {useCallback, useEffect, useMemo, useState} from 'react';
import s from './Paqueteria.module.css';
import {useQuery} from "../../hooks/useQuery";
import {useLocation} from "react-router-dom";
import {useStorageItem} from "@capacitor-community/storage-react";
import {
	IonAlert,
	IonButton, IonCardContent,
	IonContent, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonLabel,
	IonLoading,
	IonPage,
	IonTitle, IonToolbar,
	useIonAlert
} from "@ionic/react";
import Header from "../Header";
import Paquete from "../Paquete";
import {search} from 'ionicons/icons';
import {debounce} from "lodash";


const Paqueteria = props => {
	const {query} = useQuery();
	const location = useLocation();
	const [paquetes, setPaquetes] = useStorageItem('buscar_paquetes', []);
	const [destinatario, setDestinatario] = useStorageItem('destinatario_paquetes', '');
	const [codigo, setCodigo] = useStorageItem('codigo_paquetes', '');
	const [page, setPage] = useStorageItem('page_paquetes',0);
	const [user, setUser] = useStorageItem('user', 'false');


	const [isError, setIsError] = useState();
	const [message, setMessage] = useState();
	const [loading, setLoading] = useState(true);

	const [present] = useIonAlert();


	useEffect(() => {
		let list = ['recibir_paquete', 'mostrar_paquete', 'paqueteria'];
		let found = false;
		for(let i = 0;i<list.length;i++){
			if(location.pathname.includes(list[i])){
				found = true;

			}
		}
		if(!found){
			setDestinatario('');
			setCodigo('');
			setPage(0);
		}

	}, [location.pathname]);


	const cargarPaquetes = useCallback(async (p, search = true) => {
		p = p || page || 0;
		let link = `/api2/paquetes?page=${p}`;

		if(search) {
			if (destinatario) link += `&destinatario=${encodeURIComponent(destinatario)}`;
			if (codigo) link += `&codigo=${encodeURIComponent(codigo)}`;
		}
		const {error, data} = await query(link, false, 'GET');

		//const {error, data} = await query(`/api2/paquetes?page=${p}`, false, 'GET');
		setLoading(false);

		if (error || !data.paquetes) {
			setMessage(error || 'Se ha producido un error desconocido');
			setIsError(true);
			return;
		}

		if(page === 0)setPaquetes(data.paquetes);

    let prev = typeof paquetes === 'string' ? JSON.parse(paquetes) : paquetes;

    prev = Array.isArray(prev) ? prev : [];
    let r = [];
    data.paquetes.forEach(paquete => {
      if (prev.find(a => a._id === paquete._id)) return;
      r.push(paquete);
    });


		setPaquetes([...prev, ...r]);
	}, [destinatario, codigo, page]);

	const buscarPorDestinatario = async () => {
		setPage(0);
		let link = `/api2/paquetes?page=0`;
		if (destinatario) link += `&destinatario=${encodeURIComponent(destinatario)}`;
		if (codigo) link += `&codigo=${encodeURIComponent(codigo)}`;
		const {error, data} = await query(link, false, 'GET');
		setLoading(false);


		if (error || !data.paquetes) {
			setMessage(error || 'Se ha producido un error desconocido');
			setIsError(true);
			return;
		}

		setPaquetes(data.paquetes);
	}

	const siguientePagina = async e => {
		await cargarPaquetes(page + 1);
		setPage(page + 1);
		e.target.complete();
	}


	useEffect(() => {
		if (location.pathname === '/paqueteria'){
/*			console.log('inPaquet: ', inPaquet);
			if(inPaquet === 'false'){
				setDestinatario('');
				setCodigo('');
				setPage(0);
				cargarPaquetes(0, false);
			}else{
				cargarPaquetes();
			}*/
			cargarPaquetes(0, true);
		}
	}, [location.pathname]);

  useEffect(() => {
    if(location.pathname === '/paqueteria'){
      buscarPorDestinatario();
    }
  }, [destinatario, codigo]);

	const resolverIncidencia = async (id) => {
		setLoading(true);
		const {error, data} = await query(`/api2/paquetes/${id}`, false, 'DELETE');
		setLoading(false);
		if (error || !data.success) {
			setMessage(error || 'Se ha producido un error desconocido');
			setIsError(true);
			return;
		}
		cargarPaquetes();
	}


	const onDelete = (id) => {
		present({
			header: 'Alert',
			message: '¿Quiere marcar el paquete como entregado?',
			buttons: [
				'Cancelar',
				{
					text: 'Si', handler: () => resolverIncidencia(id)
				}
			]
		});
	}


  let pendiente = useMemo(() => {
    let paq = [];
    try{
      paq = JSON.parse(paquetes);
    }catch(err){
      paq = paquetes;
    }
    return Array.isArray(paq) ? paq?.filter(paquete => paquete.estado === 'pendiente') : []
  }, [paquetes]);

  let entregados = useMemo(() => {
    let paq = [];
    try{
      paq = JSON.parse(paquetes);
    }catch(err){
      paq = paquetes;
    }
    return Array.isArray(paq) ? paq?.filter(paquete => paquete.estado === 'entregado') : []
  }, [paquetes]);

  if(!paquetes)return <div>Cargando...</div>



	return (
		<IonPage>
			<Header title="Paquetes"/>

			<IonContent fullscreen>
				<IonLoading message="Cargando..." duration={0} isOpen={loading}/>
				<IonAlert
					isOpen={isError}
					onDidDismiss={() => setIsError(false)}
					header={"Error!"}
					message={message}
					buttons={["Aceptar"]}
				/>

				{user === 'conserje' &&
				<IonButton style={{marginBottom: '10px'}} routerLink="/nuevo_paquete" expand="block" disabled={loading}>Registrar
					nuevo paquete</IonButton>}


				{!pendiente.length && !entregados.length && <IonCardContent>No se han encontrado paquetes</IonCardContent>}


				<IonItem style={{marginBottom: '10px', marginTop: '20px'}}>
					<IonInput
						placeholder="Destinatario"
						value={destinatario}
						onIonChange={(e) => setDestinatario(e.detail.value)}
					/>

				</IonItem>
				<IonItem style={{marginBottom: '10px', marginTop: '20px'}}>

					<IonInput
						placeholder="Código"
						value={codigo}
						type="number"
						onIonChange={(e) => setCodigo(e.detail.value)}
					/>
				</IonItem>
				<IonButton style={{marginBottom: '20px'}} onClick={buscarPorDestinatario}>
					<IonIcon slot="icon-only" icon={search} style={{marginRight: '20px'}}/> Buscar
				</IonButton>

				{pendiente.length > 0 &&
				<>
					<IonToolbar>
						<IonTitle>Pendientes</IonTitle>
					</IonToolbar>
					{pendiente.map((paquete, i) => <Paquete paquete={paquete} key={paquete._id}/>)}
				</>}
				{entregados.length > 0 && <>
					<IonToolbar>
						<IonTitle>Entregados</IonTitle>
					</IonToolbar>
					{entregados.map((paquete, i) => <Paquete paquete={paquete} key={paquete._id}/>)}
				</>}


				<IonInfiniteScroll
					onIonInfinite={siguientePagina}
					threshold="100px">
					<IonInfiniteScrollContent
						loadingSpinner="bubbles"
						loadingText="Cargando..."
					/>
				</IonInfiniteScroll>
			</IonContent>
		</IonPage>
	);
};


export default Paqueteria;
