import React from 'react';
import s from './Menu.module.css';
import {IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, useIonAlert} from "@ionic/react";
import {
  calendarClearOutline,
  fitnessOutline,
  cubeOutline,
  storefrontOutline,
  personOutline
} from "ionicons/icons";
import {useStorageItem} from "@capacitor-community/storage-react";



const Menu = props => {
  const [user, setUser] = useStorageItem('user', 'false');

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonCard>
            <IonItem routerLink="/reservas">
              <IonIcon icon={calendarClearOutline}/>
              <IonCardContent>Reservas</IonCardContent>
            </IonItem>
          </IonCard>
        </IonCol>
        <IonCol>
          <IonCard>
            <IonItem routerLink="/incidencias">
              <IonIcon icon={fitnessOutline}/>
              <IonCardContent>Incidencias</IonCardContent>
            </IonItem>
          </IonCard>
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <IonCard>
            <IonItem routerLink="/paqueteria">
              <IonIcon icon={cubeOutline}/>
              <IonCardContent>Paquetería</IonCardContent>
            </IonItem>
          </IonCard>
        </IonCol>
        {user !== 'vecino' &&
        <IonCol>
          <IonCard>
            <IonItem routerLink="/proveedores">
              <IonIcon icon={storefrontOutline}/>
              <IonCardContent>Proveedores</IonCardContent>
            </IonItem>
          </IonCard>
        </IonCol>
        }
      </IonRow>

      <IonRow>
        <IonCol>
          <IonCard>
            <IonItem routerLink="/perfil">
              <IonIcon icon={personOutline}/>
              <IonCardContent>Perfil</IonCardContent>
            </IonItem>
          </IonCard>
        </IonCol>
      </IonRow>

    </IonGrid>
  );
};


export default Menu;
