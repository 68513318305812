import React, {useEffect, useState} from 'react';
import s from './Perfil.module.css';
import {useQuery} from "../../hooks/useQuery";
import {useHistory} from "react-router";
import {
  IonAlert, IonButton, IonButtons, IonCard, IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader, IonIcon, IonInput, IonItem, IonLabel,
  IonLoading, IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar, useIonAlert, useIonViewDidEnter
} from "@ionic/react";
import {exitOutline, personCircle} from "ionicons/icons";
import {useLocation} from "react-router-dom";
import Header from "../Header";
import {useStorageItem} from "@capacitor-community/storage-react";
import {validatePassword} from "../../util/validar";

const Perfil = props => {
  const {query} = useQuery();
  const location = useLocation();
  const history = useHistory();

  const [user, setUser] = useStorageItem('user', 'false');
  const [token, setToken] = useStorageItem('token', 'false');

  const [present] = useIonAlert();


  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [vivienda, setVivienda] = useState();
  const [newPassword, setNewPassword] = useState();

  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);


  const validarDatos = () => {
    if (!name || name.length < 2) {
      setMessage('Se require el nombre');
      setIsError(true);
      return;
    }

    if (!surname || surname.length < 2) {
      setMessage('Se require el apellido');
      setIsError(true);
      return;
    }

    return true;
  }

  const handleSubmit = async () => {
    if (!validarDatos()) return;
    setLoading(true);

    const {error, data} = await query('/api2/perfil', {
      name, surname
    }, 'PUT');

    setLoading(false);

    if (error || !data.success) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    history.replace('/home');
  }


  const cargarPerfil = async () => {
    setLoading(true);
    const {error, data} = await query(`/api2/perfil`, false, 'GET');
    setLoading(false);

    if (error || !data.perfil) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }
    setName(data.perfil.name);
    setSurname(data.perfil.surname);
    setVivienda(data.perfil.vivienda);


  }

  useEffect(() => {
    if (location.pathname === '/perfil') cargarPerfil();
  }, [location.pathname]);

  const onSalir = () => {
    present({
      header: 'Confirmación',
      message: '¿Quiere salir de su cuenta?',
      buttons: [
        'Cancelar',
        {
          text: 'Si', handler: () => salir()
        }
      ]
    });
  }

  const salir = () => {
    setUser('false');
    setToken('false');
    history.replace('/login');
  }

  const onDarseDeBaja = () => {
    const onSalir = () => {
      present({
        header: '¡Cuidado!',
        message: '¿Quiere ELIMINAR su cuenta? Esta acción no se puede revertir!',
        buttons: [
          'Cancelar',
          {
            text: 'Si', handler: () => darseDeBaja()
          }
        ]
      });
    }
  }
  const darseDeBaja = async () => {
    setLoading(true);
    const {error, data} = await query('/api2/user', false, 'DELETE');
    setLoading(false);
    if (error || !data.success) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }
    salir();
  }


  const passwordChange = async () => {
    if(!newPassword || !validatePassword(newPassword)){
      setMessage("La contraseña debe tener minimo 6 carácteres");
      setIsError(true);
      return;
    }
    setLoading(true);
    const {error, data} = await query('/api2/user_pw', {pw: newPassword}, 'PUT');


    if (error || !data.success) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }
    alert('Listo! Vuelva a entrar con su nueva contraseña');
    salir();
  }


  return (
    <IonPage>
      <Header title="Perfil"/>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonLoading message="Cargando..." duration={0} isOpen={loading}/>
        <IonGrid style={{textAlign: 'left'}}>
          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={isError}
                onDidDismiss={() => setIsError(false)}
                cssClass="my-custom-class"
                header={"Error!"}
                message={message}
                buttons={["Dismiss"]}
              />
            </IonCol>
          </IonRow>
          <IonRow style={{textAlign: 'center'}}>
            <IonCol>
              <IonIcon
                style={{fontSize: "70px", color: "#0040ff"}}
                icon={personCircle}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Nombre</IonLabel>
                <IonInput
                  value={name}
                  onIonChange={(e) => setName(e.detail.value)}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Apellidos</IonLabel>
                <IonInput
                  value={surname}
                  onIonChange={(e) => setSurname(e.detail.value)}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={handleSubmit} disabled={loading}>Cambiar datos</IonButton>
            </IonCol>
          </IonRow>
          {vivienda && <>
            <IonRow style={{textAlign: 'center'}}>
              <IonCol><IonToolbar><IonTitle>Vivienda</IonTitle></IonToolbar></IonCol>
            </IonRow>
            <IonRow>
              <IonCol>Número</IonCol>
              <IonCol>{vivienda.numero}</IonCol>
              <IonCol>Portal</IonCol>
              <IonCol>{vivienda.portal}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>Planta</IonCol>
              <IonCol>{vivienda.planta}</IonCol>
              <IonCol>Letra</IonCol>
              <IonCol>{vivienda.letra}</IonCol>
            </IonRow>
          </>
          }

          <IonRow style={{marginTop: '20px'}}>
            <IonCol>
              <IonButton onClick={() => onSalir()}>
                <IonIcon icon={exitOutline}/>
                <IonCardContent>Salir</IonCardContent>
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Contraseña nueva</IonLabel>
                <IonInput
                  type="password"
                  value={newPassword}
                  onIonChange={(e) => setNewPassword(e.detail.value)}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={passwordChange} disabled={loading}>Cambiar contraseña</IonButton>
            </IonCol>
          </IonRow>



          <IonRow style={{marginTop: '20px'}}>
            <IonCol>
              <IonButton onClick={() => onDarseDeBaja()} color="danger">
                <IonCardContent>Darse de baja</IonCardContent>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};


export default Perfil;
