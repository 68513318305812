import React from 'react';
import s from './MostrarPaquete.module.css';
import {matchPath, useHistory} from "react-router";
import Header from "../Header";
import {IonAlert, IonContent, IonLoading, IonPage} from "@ionic/react";
import Paquete from "../Paquete";

const MostrarPaquete = props => {
  const history = useHistory();


  const match = matchPath(history.location.pathname, {
    path: '/mostrar_paquete/:id',
    exact: true,
    strict: false
  });

  if(!match)return <div/>

  return (
    <IonPage>
      <Header title="Paquete"/>
      <IonContent fullscreen className="ion-padding">
        <Paquete id={match.params.id}/>
      </IonContent>
    </IonPage>
  );
};


export default MostrarPaquete;
