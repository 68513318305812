import React from 'react';
import s from './LoginRoute.module.css';
import {useStorageItem} from "@capacitor-community/storage-react";
import {Redirect} from "react-router-dom";

const LoginRoute = props => {
  const [token] = useStorageItem('token', 'false');

  if(token === undefined)return <div>Cargando...</div>
  if(token !== 'false')return <Redirect to={"/home"}/>
  return <Redirect to={"/login"}/>
};


export default LoginRoute;
