import React, {useEffect, useState} from 'react';
import s from './Paquete.module.css';
import {IonCard, IonCardContent, IonCol, IonGrid, IonImg, IonItem, IonLabel, IonRow, IonTitle} from "@ionic/react";
import moment from "moment";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import {useQuery} from "../../hooks/useQuery";
import {useStorageItem} from "@capacitor-community/storage-react";

const Paquete = ({paquete: _paquete, id}) => {
  const {query} = useQuery();
  const [paquete, setPaquete] = useState(_paquete);
  const history = useHistory();
  const location = useLocation();

  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useStorageItem('user', 'false');



  const cargarPaquete = async () => {
    setLoading(true);

    const {error, data} = await query(`/api2/paquetes/${id}`, false, 'GET');
    setLoading(false);
    if (error || !data.paquete) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }
    setPaquete(data.paquete);
  }

  useEffect(() => {
    if(id && location.pathname.includes('paquete')){
      cargarPaquete();
    }
  }, [id]);


  if(!paquete)return <div>Cargando...</div>

  let link = paquete.estado === 'entregado' ? `/mostrar_paquete` : '/recibir_paquete'

  let ionCardProps = {};
  if(!id && user !== 'vecino')ionCardProps.routerLink = `${link}/${paquete._id}`;

  return (<IonCard {...ionCardProps}>
    <IonCardContent>
      <IonGrid>
        <IonRow>
          <IonCol>
            Destinatario
          </IonCol>
          <IonCol>
            {paquete.destinatario}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            Proveedor
          </IonCol>
          <IonCol>
            {paquete.proveedor}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            Código
          </IonCol>
          <IonCol>
            {paquete.codigo}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            Fecha de recepción
          </IonCol>
          <IonCol>
            {moment(paquete.fecha_recepcion).format('LLL')}
          </IonCol>
        </IonRow>
        {paquete.fecha_entrega &&
        <IonRow>
          <IonCol>
            Fecha de entrega
          </IonCol>
          <IonCol>
            {moment(paquete.fecha_entrega).format('LLL')}
          </IonCol>
        </IonRow>
        }
        {paquete.conserje &&
        <IonRow>
          <IonCol>
            Conserje
          </IonCol>
          <IonCol>
            {paquete.conserje.name + ' ' + paquete.conserje.surname}
          </IonCol>
        </IonRow>
        }
        {paquete.receptor &&
        <IonRow>
          <IonCol>
            Recogido por
          </IonCol>
          <IonCol>
            {paquete.receptor}
          </IonCol>
        </IonRow>
        }
        {paquete.vivienda && id && <>
          <IonRow>
            <IonCol style={{fontSize: '20px'}}>Vivienda</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Número</IonCol>
            <IonCol>{paquete.vivienda.numero}</IonCol>
            <IonCol>Portal</IonCol>
            <IonCol>{paquete.vivienda.portal}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>Planta</IonCol>
            <IonCol>{paquete.vivienda.planta}</IonCol>
            <IonCol>Letra</IonCol>
            <IonCol>{paquete.vivienda.letra}</IonCol>
          </IonRow>
        </>
        }
      </IonGrid>
      {id && <IonImg src={paquete.firma} className={s.signature}/>}
    </IonCardContent>
  </IonCard>);
};


export default Paquete;
