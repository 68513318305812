import React, {useEffect, useState} from 'react';
import s from './CreateNewPassword.module.css';
import {
  IonAlert, IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon, IonInput, IonItem, IonLabel,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {personCircle} from "ionicons/icons";
import {useQuery} from "../../hooks/useQuery";
import {validatePassword} from "../../util/validar";
import {useLocation} from "react-router-dom";

const CreateNewPassword = props => {
  const {query} = useQuery();
  const location = useLocation();

  const [pw, setPw] = useState();
  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [done, setDone] = useState(false);


  const handleSubmit = async () => {
    if (!validatePassword(pw)) {
      setMessage('La contraseña es inválida');
      setIsError(true);
      return;
    }

    const params = new URLSearchParams(location.search);
    const h = params.get('h');
    const id = params.get('id');


    const {error, data} = await query('/api2/reset_pw', {pw, h, id}, 'POST');

    if (error) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    if(!data.success){
      setMessage('Su token ha expirado');
      setIsError(true);
      return;
    }

    setDone(true);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Restablecer contraseña</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={isError}
                onDidDismiss={() => setIsError(false)}
                header={"Error!"}
                message={message}
                buttons={["Aceptar"]}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon
                style={{fontSize: "70px", color: "#0040ff"}}
                icon={personCircle}
              />
            </IonCol>
          </IonRow>
          {done ?
            <>
              <IonRow>
                <IonCol>
                  Gracias! Su contraseña ha sido cambiada!
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonItem routerLink="/login">
                    <IonLabel>Entrar con su nueva contraseña</IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
            </>
            : <>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Nueva contraseña</IonLabel>
                    <IonInput
                      type="password"
                      value={pw}
                      onIonChange={(e) => setPw(e.detail.value)}
                    >
                    </IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand="block" onClick={handleSubmit}>Restablecer contraseña</IonButton>
                </IonCol>
              </IonRow>
            </>
          }
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};


export default CreateNewPassword;
