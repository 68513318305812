import React, {useState} from 'react';
import s from './Register.module.css';
import {
  IonAlert, IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon, IonInput, IonItem, IonLabel, IonLoading,
  IonPage,
  IonRow, IonSelect, IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {personCircle} from "ionicons/icons";

import viviendas from '../../util/viviendas.json'
import {validateEmail, validatePassword} from "../../util/validar";
import {useQuery} from "../../hooks/useQuery";
import {useHistory} from "react-router";

const Register = props => {
  const {query} = useQuery();
  const history = useHistory();


  const [name, setName] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [surname, setSurname] = useState();

  const [vivienda_numero, setVivienda_numero] = useState(viviendas.numero[0]);
  const [vivienda_portal, setVivienda_portal] = useState(viviendas.portal[0]);
  const [vivienda_planta, setVivienda_planta] = useState(viviendas.planta[0]);
  const [vivienda_letra, setVivienda_letra] = useState(viviendas.letra[0]);


  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);


  const validarDatos = () => {
    if(!validateEmail(email)){
      setMessage('El email es inválido');
      setIsError(true);
      return;
    }
    if(!password || !validatePassword(password)){
      setMessage("La contraseña debe tener minimo 6 carácteres");
      setIsError(true);
      return;
    }

    if(!name || name.length < 2){
      setMessage('Se require el nombre');
      setIsError(true);
      return;
    }

    if(!surname || surname.length < 2){
      setMessage('Se require el apellido');
      setIsError(true);
      return;
    }

    return true;

  }

  const handleSubmit = async () => {
    if(!validarDatos())return;
    setLoading(true);

    const {error, data} = await query('/api2/register', {
      name, surname, pw: password, email, vivienda:{
        numero: vivienda_numero,
        letra: vivienda_letra,
        portal: vivienda_portal,
        planta: vivienda_planta
      },
      type: 'user'
    }, 'POST');

    setLoading(false);

    if(error || !data.success){
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    history.replace('/login');

  }


  const unique = (item, pos, self) => self.indexOf(item) === pos;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Registro</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonLoading message="Cargando..." duration={0} isOpen={loading}/>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={isError}
                onDidDismiss={() => setIsError(false)}
                header={"Error!"}
                message={message}
                buttons={["Aceptar"]}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon
                style={{fontSize: "70px", color: "#0040ff"}}
                icon={personCircle}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Número</IonLabel>
                <IonSelect value={vivienda_numero} placeholder="Elegir" onIonChange={e => setVivienda_numero(e.detail.value)}>
                  {viviendas.numero.filter(unique).map(n => <IonSelectOption value={n}>{n}</IonSelectOption>)}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>Portal</IonLabel>
                <IonSelect value={vivienda_portal} placeholder="Elegir" onIonChange={e => {
                  setVivienda_portal(e.detail.value)
                  setVivienda_planta(viviendas.planta[viviendas.portal.indexOf(e.detail.value)]);
                }}>
                  {viviendas.portal.filter(unique).map(n => <IonSelectOption value={n}>{n}</IonSelectOption>)}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Planta</IonLabel>
                <IonSelect value={vivienda_planta} placeholder="Elegir" onIonChange={e => setVivienda_planta(e.detail.value)}>
                  {viviendas.planta.filter((item, pos) => {
                    let portales = viviendas.portal.map((portal, pos) => ({portal, pos})).filter(p => {
                      return p.portal === vivienda_portal && viviendas.numero[pos] === vivienda_numero
                    });
                    return portales.find(p => p.pos === pos);
                  }).filter(unique).map(n => <IonSelectOption value={n}>{n}</IonSelectOption>)}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>Letra</IonLabel>
                <IonSelect value={vivienda_letra} placeholder="Elegir" onIonChange={e => setVivienda_letra(e.detail.value)}>
                  {viviendas.letra.filter((item, pos) => {
                    let plantas = viviendas.planta.map((planta, pos) => ({planta, pos})).filter(p => {
                      return p.planta === vivienda_planta && viviendas.portal[pos] === vivienda_portal && viviendas.numero[pos] === vivienda_numero
                    });
                    return plantas.find(p => p.pos === pos);
                  }).filter(unique).map(n => <IonSelectOption value={n}>{n}</IonSelectOption>)}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Nombre</IonLabel>
                <IonInput value={name} onIonChange={(e) => setName(e.detail.value)}/>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Apellidos</IonLabel>
                <IonInput value={surname} onIonChange={(e) => setSurname(e.detail.value)}/>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  onIonChange={(e) => setEmail(e.detail.value)}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>



          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Contraseña</IonLabel>
                <IonInput
                  type="password"
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value)}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={handleSubmit} disabled={loading}>Registro</IonButton>

              <IonItem routerLink="/login">
                <IonLabel>¿Ya tienes cuenta? Entrar</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};


export default Register;
