import React, {useState} from 'react';
import s from './NuevoProveedor.module.css';
import {useQuery} from "../../hooks/useQuery";
import {useHistory} from "react-router";
import {
  IonAlert, IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow, IonTextarea
} from "@ionic/react";
import Header from "../Header";

const NuevoProveedor = props => {
  const {query} = useQuery();
  const history = useHistory();


  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');


  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);


  const handleSubmit = async () => {
    setLoading(true);
    const {error, data} = await query('/api2/proveedores', {
      nombre, descripcion
    }, 'POST');

    setLoading(false);

    if (error || !data.success) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    history.replace('/proveedores');
  }

  const unique = (item, pos, self) => self.indexOf(item) === pos;


  return (
    <IonPage>
      <Header title="Nuevo Proveedor"/>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonLoading message="Cargando..." duration={0} isOpen={loading}/>
        <IonAlert
          isOpen={isError}
          onDidDismiss={() => setIsError(false)}
          header={"Error!"}
          message={message}
          buttons={["Cancelar"]}
        />
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Nombre proveedor</IonLabel>
                <IonInput
                  value={nombre}
                  onIonChange={(e) => setNombre(e.detail.value)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Descripción</IonLabel>
                <IonTextarea
                  value={descripcion}
                  onIonChange={(e) => setDescripcion(e.detail.value)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        {nombre.length > 2 && descripcion.length > 2 &&
        <IonButton style={{marginTop: '20px'}} expand="block" onClick={handleSubmit}>
          Guardar
        </IonButton>}

      </IonContent>
    </IonPage>
  );
};


export default NuevoProveedor;
