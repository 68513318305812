import {
  IonButtons,
  IonContent, IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu, IonMenuButton,
  IonMenuToggle,
  IonToolbar,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import {

  calendarClearOutline, cubeOutline, fitnessOutline,
homeOutline,
 personOutline, storefrontOutline,
} from 'ionicons/icons';
import './MenuBar.css';
import {useStorageItem} from "@capacitor-community/storage-react";

const appPages = [
  {
    title: 'Inicio',
    url: '/home',
    iosIcon: homeOutline,
    mdIcon: homeOutline,
    vecino: true
  },
  {
    title: 'Reservas',
    url: '/reservas',
    iosIcon: calendarClearOutline,
    mdIcon: calendarClearOutline,
    vecino: true
  },
  {
    title: 'Incidencias',
    url: '/incidencias',
    iosIcon: fitnessOutline,
    mdIcon: fitnessOutline,
    vecino: true
  },
  {
    title: 'Paqueteria',
    url: '/paqueteria',
    iosIcon: cubeOutline,
    mdIcon: cubeOutline,
    vecino: true
  },
  {
    title: 'Proveedores',
    url: '/proveedores',
    iosIcon: storefrontOutline,
    mdIcon: storefrontOutline,
    vecino: false
  },
  {
    title: 'Perfil',
    url: '/perfil',
    iosIcon: personOutline,
    mdIcon: personOutline,
    vecino: true
  }
];


const Menu: React.FC = () => {
  const location = useLocation();
  const [user, setUser] = useStorageItem('user', 'false');

  return (
    <IonMenu contentId="main" type="overlay">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader style={{marginBottom: '20px'}}>Cirauqui 7</IonListHeader>
{/*          <IonNote>hi@ionicframework.com</IonNote>*/}
          {appPages.filter(({vecino}) => (user === 'vecino' && vecino) || user !== 'vecino').map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
