import React, {useEffect, useState} from 'react';
import s from './NuevoPaquete.module.css';
import {useQuery} from "../../hooks/useQuery";
import {matchPath, useHistory} from "react-router";
import {
  IonAlert, IonButton,
  IonButtons, IonCol,
  IonContent, IonGrid,
  IonHeader, IonInput, IonItem, IonLabel, IonLoading,
  IonMenuButton,
  IonPage, IonRow, IonSelect, IonSelectOption, IonTextarea,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter
} from "@ionic/react";
import viviendas from "../../util/viviendas.json";
import Header from "../Header";
import {useLocation} from "react-router-dom";

const NuevoPaquete = props => {
  const {query} = useQuery();
  const history = useHistory();
  const location = useLocation();

  const [destinatario, setDestinatario] = useState('');
  const [proveedor, setProveedor] = useState('');
  const [codigo, setCodigo] = useState(0);

  const [vivienda_numero, setVivienda_numero] = useState(viviendas.numero[0]);
  const [vivienda_portal, setVivienda_portal] = useState(viviendas.portal[0]);
  const [vivienda_planta, setVivienda_planta] = useState(viviendas.planta[0]);
  const [vivienda_letra, setVivienda_letra] = useState(viviendas.letra[0]);




  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);


  const cargarCodigo = async () => {
    setLoading(true);
    const {error, data} = await query('/api2/paquetes_codigo', false, 'GET');
    if (error || !data.codigo) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    setCodigo(data.codigo);
    setLoading(false);
  }
  useEffect(() => {
    if(location.pathname.includes('nuevo_paquete'))cargarCodigo();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);


    const {error, data} = await query('/api2/paquetes', {
      destinatario, proveedor, codigo,
      vivienda:{
        numero: vivienda_numero,
        letra: vivienda_letra,
        portal: vivienda_portal,
        planta: vivienda_planta
      }
    }, 'POST');

    setLoading(false);

    if (error || !data.success) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    history.replace('/paqueteria');
  }

  const unique = (item, pos, self) => self.indexOf(item) === pos;


  return (
    <IonPage>
      <Header title="Nuevo paquete"/>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonLoading message="Cargando..." duration={0} isOpen={loading}/>
        <IonAlert
          isOpen={isError}
          onDidDismiss={() => setIsError(false)}
          header={"Error!"}
          message={message}
          buttons={["Cancelar"]}
        />
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Nombre destinatario</IonLabel>
                <IonInput
                  value={destinatario}
                  onIonChange={(e) => setDestinatario(e.detail.value)}
                />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>Código del paquete</IonLabel>
                <IonInput
                  value={codigo}
                  onIonChange={(e) => setCodigo(e.detail.value)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Proveedor</IonLabel>
                <IonInput
                  value={proveedor}
                  onIonChange={(e) => setProveedor(e.detail.value)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Número</IonLabel>
                <IonSelect value={vivienda_numero} placeholder="Elegir" onIonChange={e => setVivienda_numero(e.detail.value)}>
                  {viviendas.numero.filter(unique).map(n => <IonSelectOption key={n} value={n}>{n}</IonSelectOption>)}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>Portal</IonLabel>
                <IonSelect value={vivienda_portal} placeholder="Elegir" onIonChange={e => {
                  setVivienda_portal(e.detail.value)
                  setVivienda_planta(viviendas.planta[viviendas.portal.indexOf(e.detail.value)]);
                }}>
                  {viviendas.portal.filter(unique).map(n => <IonSelectOption key={n} value={n}>{n}</IonSelectOption>)}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Planta</IonLabel>
                <IonSelect value={vivienda_planta} placeholder="Elegir" onIonChange={e => setVivienda_planta(e.detail.value)}>
                  {viviendas.planta.filter((item, pos) => {
                    let portales = viviendas.portal.map((portal, pos) => ({portal, pos})).filter(p => {
                      return p.portal === vivienda_portal && viviendas.numero[pos] === vivienda_numero
                    });
                    return portales.find(p => p.pos === pos);
                  }).filter(unique).map(n => <IonSelectOption key={n} value={n}>{n}</IonSelectOption>)}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonLabel>Letra</IonLabel>
                <IonSelect value={vivienda_letra} placeholder="Elegir" onIonChange={e => setVivienda_letra(e.detail.value)}>
                  {viviendas.letra.filter((item, pos) => {
                    let plantas = viviendas.planta.map((planta, pos) => ({planta, pos})).filter(p => {
                      return p.planta === vivienda_planta && viviendas.portal[pos] === vivienda_portal && viviendas.numero[pos] === vivienda_numero
                    });
                    return plantas.find(p => p.pos === pos);
                  }).filter(unique).map(n => <IonSelectOption key={n} value={n}>{n}</IonSelectOption>)}
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        {destinatario.length > 2 && proveedor.length > 2 &&
        <IonButton style={{marginTop: '20px'}} expand="block" onClick={handleSubmit}>
          Guardar
        </IonButton>}

      </IonContent>
    </IonPage>
  );
};


export default NuevoPaquete;
