import {IonBackButton, IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar} from "@ionic/react";
import React from "react";


const Header = ({title}) => {
  return <IonHeader style={{marginBottom:'10px'}}>
    <IonToolbar>
      <IonTitle>{title}</IonTitle>
      <IonButtons slot="start">
        <IonBackButton/>
        <IonMenuButton/>
      </IonButtons>
    </IonToolbar>
  </IonHeader>
}

export default Header;
