import React, {useEffect, useState} from 'react';
import s from './Reservas.module.css';
import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  IonBackButton, IonAlert,
} from "@ionic/react";
import {trashOutline} from "ionicons/icons";
import ZonasComunes from "../ZonasComunes";
import {useQuery} from "../../hooks/useQuery";
import moment from 'moment';
import {useLocation} from "react-router-dom";
import Header from "../Header";
import {useStorageItem} from "@capacitor-community/storage-react";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const Reservas = props => {
  const {query} = useQuery();
  const location = useLocation();
  const [reservas, setReservas] = useState([]);
  const [pendientesDePago, setPendientesDePago] = useState([]);
  const [zonas, setZonas] = useState([]);


  const [user, setUser] = useStorageItem('user', 'false');

  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);

  const [present] = useIonAlert();

  const cargarReservas = async () => {
    const {error, data} = await query('/api2/reservas', false, 'GET');
    setLoading(false);

    if (error || !data.reservas) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    setReservas(data.reservas);
  }

  const cargarPendientesDePago = async () => {
    const {error, data} = await query('/api2/pendientes_de_pago', false, 'GET');
    setLoading(false);

    if (error || !data.reservas) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    setPendientesDePago(data.reservas);
  }
  const cargarZonas = async () => {
    const {error, data} = await query('/api2/zonasComunes', false, 'GET');
    if (error || !data.zonas) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }


    setZonas(data.zonas.sort((a,b) => {
      if(a.order > b.order)return 1;
      if(a.order < b.order)return -1;
      return 0;
    }));
  }


  useEffect(() => {
    if (location.pathname !== '/reservas') return;
    cargarReservas();
    cargarZonas();
    if(user === 'vecino')cargarPendientesDePago();
  }, [user, location.pathname]);


  const eliminarReserva = async (id) => {
    setLoading(true);
    const {error, data} = await query(`/api2/reservas/${id}`, false, 'DELETE');
    console.log(error);
    setLoading(false);
    if (error || !data.success) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }
    cargarReservas();
  }


  const onDelete = reserva => {
    if(user !== 'vecino')return;
    present({
      header: zonas.find(a => a._id === reserva.zona).name,
      message: `¿Quieres cancelar la reserva de las <b>${reserva.session}</b>?`,
      buttons: [
        'No',
        {
          text: 'Sí', handler: () => eliminarReserva(reserva._id)
        }
      ]
    });
  }

  if (!zonas.length) return <div/>;


  return (
    <IonPage>
      <Header title="Reservas"/>
      <IonContent fullscreen>
        <IonLoading message="Cargando..." duration={0} isOpen={loading || !zonas.length}/>
        <IonAlert
          isOpen={isError}
          onDidDismiss={() => setIsError(false)}
          header={"Error!"}
          message={message}
          buttons={["Aceptar"]}
        />

        {pendientesDePago.length > 0 && <>
          <IonToolbar><IonTitle size="large">Reservas pendientes de pago</IonTitle></IonToolbar>
          {pendientesDePago.map((reserva, i) => <IonCard key={i}>
            <IonItem>
              <IonCardContent>
                  <div>
                    {zonas.find(zona => zona._id === reserva.zona).name}
                    {' '}
                    {dayjs(reserva.date).utc().format('YYYY-MM-DD')}
                    {' '}
                    {user === 'conserje' && <div>
                      {reserva.user.name} {' '} {reserva.user.surname}
                    </div>}
                    <div style={{fontWeight: 'bold'}}>
                      {reserva.session}
                    </div>
                  </div>
              </IonCardContent>
            </IonItem>
          </IonCard>)}

        </>}


        {reservas.length > 0 &&
        <>
          <IonToolbar><IonTitle size="large">Mis reservas</IonTitle></IonToolbar>
          {reservas.map((reserva, i) => <IonCard key={i}>
            <IonItem onClick={() => onDelete(reserva)}>
              <IonCardContent>
                <div style={{display: 'flex'}}>
                  {user === 'vecino' && <div style={{marginRight: '10px'}}>
                    <IonIcon icon={trashOutline} size="large"/>
                  </div>}

                  <div>
                    {zonas.find(zona => zona._id === reserva.zona).name}
                    {' '}
                    {dayjs(reserva.date).utc().format('YYYY-MM-DD')}
                    {' '}
                    {user === 'conserje' && <div>
                      {reserva.user.name} {' '} {reserva.user.surname}
                    </div>}
                    <div style={{fontWeight: 'bold'}}>
                      {reserva.session}
                    </div>
                  </div>
                </div>
              </IonCardContent>
            </IonItem>
          </IonCard>)}
        </>
        }

        {user === 'conserje' && reservas.length === 0 && <IonCardContent>No hay zonas reservadas</IonCardContent>}


        {user === "vecino" && <ZonasComunes zonas={zonas} setZonas={setZonas}/>}

      </IonContent>
    </IonPage>
  );
};


export default Reservas;
