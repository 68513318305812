import {Redirect, Route, useLocation} from 'react-router-dom';
import {IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Login from "./pages/Login";
import Register from "./pages/Register";
import PasswordReset from "./pages/PasswordReset";
import Reservas from "./components/Reservas";
import Incidencias from "./components/Incidencias";
import Paqueteria from "./components/Paqueteria";
import Proveedores from "./components/Proveedores";
import {useStorageItem} from "@capacitor-community/storage-react";
import NuevaReserva from "./components/NuevaReserva";
import moment from "moment";
import 'moment/locale/es'
import NuevaIncidencia from "./components/NuevaIncidencia";
import MenuBar from "./components/MenuBar";
import LoginRoute from "./components/LoginRoute";
import Perfil from "./components/Perfil";
import NuevoPaquete from "./components/NuevoPaquete";
import RecibirPaquete from "./components/RecibirPaquete";
import MostrarPaquete from "./components/MostrarPaquete";

import {ActionPerformed, PushNotificationSchema, PushNotifications, Token} from "@capacitor/push-notifications";
import {useEffect} from "react";
import NuevoProveedor from "./components/NuevoProveedor";
import CreateNewPassword from "./pages/CreateNewPassword";


setupIonicReact();

const App: React.FC = () => {
  moment.locale('es');
  const [token] = useStorageItem('token', 'false');
  const [notification_token, setNotificationToken ] = useStorageItem('notification_token', '');


  useEffect(() => {
    PushNotifications.requestPermissions().then(result => {
      if(result.receive === 'granted'){
        //alert('Registrado');
        PushNotifications.register();
      }else{
        //alert('Faltan permisos');
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      //alert(token);
      setNotificationToken(token.value);
      //alert('Push registration success, token: ' + token.value);
    });
    PushNotifications.addListener('registrationError', error => {
      //alert(error);
    });
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      //alert(notification);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      //alert(notification);
    });

  }, []);

  if (token === undefined) return <div>Cargando...</div>

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          {(token !== 'false') && <MenuBar/>}

          <IonRouterOutlet id="main" animated={false} >
              <Route>
                <LoginRoute/>
              </Route>


              <Route exact path="/home">
                <Home/>
              </Route>


              <Route exact path="/login">
                <Login/>
              </Route>

              <Route exact path="/register">
                <Register/>
              </Route>

              <Route exact path="/pw_reset">
                <PasswordReset/>
              </Route>

              <Route exact path="/new_pass">
                <CreateNewPassword/>
              </Route>

              <Route exact path="/reservas">
                <Reservas/>
              </Route>
              <Route path="/reservas/:id" component={NuevaReserva}/>


              <Route exact path="/incidencias">
                <Incidencias/>
              </Route>
              <Route exact path="/nueva_incidencia" component={NuevaIncidencia}/>
              <Route path="/nueva_incidencia/:id" component={NuevaIncidencia}/>


              <Route exact path="/perfil">
                <Perfil/>
              </Route>


              <Route exact path="/paqueteria">
                <Paqueteria/>
              </Route>
              <Route exact path="/nuevo_paquete" component={NuevoPaquete}/>
              <Route exact path="/recibir_paquete/:id" component={RecibirPaquete}/>
              <Route exact path="/mostrar_paquete/:id" component={MostrarPaquete}/>

              <Route exact path="/proveedores">
                <Proveedores/>
              </Route>
            <Route exact path="/nuevo_proveedor" component={NuevoProveedor}/>

          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;
