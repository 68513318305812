import React, {useEffect, useState} from 'react';
import s from './Proveedores.module.css';
import {useQuery} from "../../hooks/useQuery";
import {useLocation} from "react-router-dom";
import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent, IonCol,
  IonContent, IonGrid, IonItem,
  IonLoading,
  IonPage, IonRow,
  IonTitle, IonToolbar,
  useIonAlert
} from "@ionic/react";
import Header from "../Header";


const Proveedor = ({proveedor, onClick}) => <IonCard onClick={e => !proveedor.fecha_salida && onClick(proveedor._id)}>
  <IonItem>
    <IonCardContent>
      <IonGrid>
        <IonRow>
          <IonCol>Proveedor: <b>{proveedor.nombre}</b></IonCol>
        </IonRow>
        <IonRow>
          <IonCol>Fecha de entrada: <b>{proveedor.fecha_entrada}</b></IonCol>
        </IonRow>
        {proveedor.fecha_salida && <IonRow>
          <IonCol>Fecha de salida: <b>{proveedor.fecha_salida}</b></IonCol>
        </IonRow>}
        <IonRow>
          <IonCol>{proveedor.descripcion}</IonCol>
        </IonRow>
      </IonGrid>
    </IonCardContent>
  </IonItem>

</IonCard>

const Proveedores = props => {
  const {query} = useQuery();
  const location = useLocation();

  const [proveedores, setProveedores] = useState([]);

  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(true);

  const [present] = useIonAlert();


  const cargarProveedores = async () => {
    setLoading(true);
    const {error, data} = await query(`/api2/proveedores`, false, 'GET');
    setLoading(false);
    if (error || !data.proveedores) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    setProveedores(data.proveedores);
  }

  const marcarResuelto = async id => {
    setLoading(true);
    const {error, data} = await query(`/api2/proveedores/${id}`, false, 'PUT');
    setLoading(false);
    if (error || !data.success) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }
    cargarProveedores();
  }

  const onClick = id => {
    present({
      header: 'Marcar resuelto',
      message: '¿Ha salido el proveedor de la comunidad?',
      buttons: [
        'No',
        {
          text: 'Si', handler: () => marcarResuelto(id)
        }
      ]
    });
  }

  useEffect(() => {
    if (location.pathname === '/proveedores') cargarProveedores();
  }, [location.pathname]);

  let en_curso = proveedores.filter(proveedor => proveedor.state === 'en curso');
  let resueltas = proveedores.filter(proveedor => proveedor.state === 'resuelto');


  return (
    <IonPage>
      <Header title="Proveedores"/>
      <IonContent fullscreen>
        <IonLoading message="Cargando..." duration={0} isOpen={loading}/>
        <IonAlert
          isOpen={isError}
          onDidDismiss={() => setIsError(false)}
          header={"Error!"}
          message={message}
          buttons={["Aceptar"]}
        />

        <IonButton style={{marginBottom: '10px'}} routerLink="/nuevo_proveedor" expand="block" disabled={loading}>Nuevo
          proveedor</IonButton>


        {en_curso.length > 0 &&
        <>
          <IonToolbar>
            <IonTitle>Dentro de la comunidad</IonTitle>
          </IonToolbar>
          {en_curso.map((proveedor, i) => <Proveedor onClick={id => onClick(id)} proveedor={proveedor} key={proveedor._id}/>)}
        </>}
        {resueltas.length > 0 && <>
          <IonToolbar>
            <IonTitle>Resueltos</IonTitle>
          </IonToolbar>
          {resueltas.map((proveedor, i) => <Proveedor onClick={id => onClick(id)} proveedor={proveedor} key={proveedor._id}/>)}
        </>}

      </IonContent>
    </IonPage>
  );
};


export default Proveedores;
