import config from "../../util/config";
import {Storage} from '@capacitor/storage';

export const encodeURIParams = (params) => {
  return Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
}

const query = async (query, data, method = 'POST', {
  headers = {},
  signal,
  replaceHeaders,
  token,
  rawBody = false
} = {}) => {
  method = method.toUpperCase();

  let options = {
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      ...headers
    },
    mode: 'cors',
    withCredentials: true,
    signal
  };

  if (replaceHeaders) options.headers = replaceHeaders;

  if (token) options.headers['Authorization'] = `Bearer ${token}`;

  if (method !== 'GET' && !rawBody) options.body = JSON.stringify(data);
  if (rawBody) options.body = data;

  let url = `${config.serverURL}${query}`;
  if (method === 'GET' && data) {
    url += '?' + encodeURIParams(data);
  }

  try {
    let r = await fetch(url, options);
    let data = await r.json();
    if (!r.ok) {
      return {error: data.error};
    }
    return {data, error: data?.error};
  } catch (err) {
    console.log(err);
    return {error: err}
  }
}

export const useQuery = () => {
  const call = (url, data, method, opts = {}) => new Promise(async (resolve) => {
    const result = await Storage.get({key: 'token'});
    opts = {...opts, token: result?.value};
    resolve(await query(url, data, method, opts));
  });

  return {
    query: call
  }
}
