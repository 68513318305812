import React, {useEffect, useState} from 'react';
import s from './Incidencias.module.css';
import {useQuery} from "../../hooks/useQuery";
import {
	IonAlert,
	IonButton, IonButtons,
	IonCard, IonCardContent,
	IonContent,
	IonHeader, IonIcon, IonItem,
	IonLoading, IonMenuButton,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonAlert,
	useIonViewDidEnter
} from "@ionic/react";
import ZonasComunes from "../ZonasComunes";
import {trashOutline} from "ionicons/icons";
import moment from "moment";
import {useLocation} from "react-router-dom";
import Header from "../Header";
import {useStorageItem} from "@capacitor-community/storage-react";
import dayjs from "dayjs";

const Incidencia = ({incidencia}) => {
	const [show, setShow] = useState(false);
	const b = (a, l = true) => a ? `${a}${l ? ', ' : ''}` : '';
	return <IonCard onClick={() => setShow(prev => !prev)}>
		{/*<IonItem routerLink={`/nueva_incidencia/${incidencia._id}`}>*/}
		<IonItem>
			<IonCardContent>
				<div style={{display: 'flex'}}>
					{/*                  <div style={{marginRight: '10px'}}>
                    <IonIcon icon={trashOutline} size="large"/>
                  </div>*/}

					<div>
						<div style={{fontWeight: 'bold', textTransform: 'capitalize'}}>
							{incidencia.title}
						</div>
						{' '}
						{moment(incidencia.date).format('YYYY-MM-DD')}
						{' '}

						<div>
							{incidencia.description}
						</div>
					</div>
				</div>
				{show && <>
					<div>Estado: {incidencia.state}</div>
					<div>
						{b(incidencia.tipo)}
						{b(incidencia.ubicacion)}
						{b(incidencia.portal)}
						{b(incidencia.poral_ubicacion)}
						{b(incidencia.garaje)}
						{b(incidencia.zonas_comunes, false)}
					</div>
					<div>{b(incidencia.observaciones, false)}</div>
					<div>Fecha alta: {dayjs(incidencia.date).format('YYYY-MM-DD hh:mm')}</div>
					{incidencia.date_end && <div>Fecha cierre: {dayjs(incidencia.date_end).format('YYYY-MM-DD hh:mm')}</div>}
				</>}
			</IonCardContent>
		</IonItem>
	</IonCard>
}


const Incidencias = props => {
	const {query} = useQuery();
	const location = useLocation();
	const [incidencias, setIncidencias] = useState([]);
	const [user, setUser] = useStorageItem('user', 'false');


	const [isError, setIsError] = useState();
	const [message, setMessage] = useState();
	const [loading, setLoading] = useState(true);

	const [present] = useIonAlert();

	const cargarIncidencias = async () => {
		const {error, data} = await query('/api2/incidencias', false, 'GET');
		setLoading(false);

		if (error || !data.incidencias) {
			setMessage(error || 'Se ha producido un error desconocido');
			setIsError(true);
			return;
		}

		setIncidencias(data.incidencias);
	}


	useEffect(() => {
		if (location.pathname === '/incidencias') cargarIncidencias();
	}, [location.pathname]);

	const resolverIncidencia = async (id) => {
		setLoading(true);
		const {error, data} = await query(`/api2/incidencias/${id}`, false, 'DELETE');
		setLoading(false);
		if (error || !data.success) {
			setMessage(error || 'Se ha producido un error desconocido');
			setIsError(true);
			return;
		}
		cargarIncidencias();
	}


	const onDelete = (id) => {
		present({
			header: 'Resolver incidencia',
			message: '¿Quiere marcar la incidencia como resuelta?',
			buttons: [
				'No',
				{
					text: 'Si', handler: () => resolverIncidencia(id)
				}
			]
		});
	}

	let en_curso = incidencias.filter(incidencia => ['en curso', 'pendiente'].includes(incidencia.state));
	let resueltas = incidencias.filter(incidencia => ['completada', 'cancelada'].includes(incidencia.state));


	return (
		<IonPage>
			<Header title="Incidencias"/>
			<IonContent fullscreen>
				<IonLoading message="Cargando..." duration={0} isOpen={loading}/>
				<IonAlert
					isOpen={isError}
					onDidDismiss={() => setIsError(false)}
					header={"Error!"}
					message={message}
					buttons={["Aceptar"]}
				/>

				{user === 'vecino' &&
					<IonButton style={{marginBottom: '10px'}} routerLink="/nueva_incidencia" expand="block" disabled={loading}>Crear
						nueva incidencia</IonButton>}


				{user !== 'vecino' && !en_curso.length && !resueltas.length &&
					<IonCardContent>No se han encontrado incidencias</IonCardContent>}

				{en_curso.length > 0 &&
					<>
						<IonToolbar><IonTitle>En curso</IonTitle></IonToolbar>
						{en_curso.map((incidencia, i) => <Incidencia incidencia={incidencia} key={incidencia._id}/>)}
					</>}
				{resueltas.length > 0 && <>
					<IonToolbar><IonTitle>Resueltas</IonTitle></IonToolbar>
					{resueltas.map((incidencia, i) => <Incidencia incidencia={incidencia} key={incidencia._id}/>)}
				</>}

			</IonContent>
		</IonPage>
	);
};


export default Incidencias;
