import React, {useEffect, useState} from 'react';
import s from './ZonasComunes.module.css';
import {
  IonCard,
  IonCardHeader, IonCardSubtitle,
  IonCardTitle,
  IonImg,
} from "@ionic/react";
import {bodyOutline, giftOutline, ribbonOutline} from "ionicons/icons";
import {useQuery} from "../../hooks/useQuery";

import SalaEventosImage from '../../resources/images/sala_eventos.jpg';
import CumpleImage from '../../resources/images/cumple.jpg';
import PadelImage from '../../resources/images/padel.jpg';


const images = {
  'Sala Actividades': SalaEventosImage,
  'Sala Eventos': CumpleImage,
  'Pista de Pádel': PadelImage,
}

const ZonasComunes = ({zonas}) => {
  if (!zonas) return <div>Cargando...</div>


  return <div>
{/*    <IonTitle size="large">Zonas comunes</IonTitle>*/}
    {zonas.map(zona => <IonCard key={zona._id} routerLink={`/reservas/${zona._id}`}>
{/*      <IonRow><IonCardContent style={{textAlign: 'center', width: '100%', whiteSpace: 'nowrap'}}>{zona.name}</IonCardContent></IonRow>
      <IonRow>{images[zona.name] && <IonImg style={{height: '200px'}} src={images[zona.name]}/>}</IonRow>   */}
      <IonCardHeader>
        <IonCardTitle >{zona.name}</IonCardTitle>
        {zona.mensaje && <IonCardSubtitle>{zona.mensaje}</IonCardSubtitle>}
      </IonCardHeader>
      {images[zona.name] && <IonImg className={s.image} style={{height: '150px'}} src={images[zona.name]}/>}


  </IonCard>)}
  </div>


};


export default ZonasComunes;
