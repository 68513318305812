import React, {useEffect, useRef, useState} from 'react';
import s from './RecibirPaquete.module.css';
import {useQuery} from "../../hooks/useQuery";
import {matchPath, useHistory} from "react-router";
import {
  IonAlert, IonButton, IonButtons,
  IonCol,
  IonContent,
  IonGrid, IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading, IonMenuButton,
  IonPage,
  IonRow, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToolbar, useIonViewDidEnter
} from "@ionic/react";
import Header from "../Header";
import CreateSignature from "../CreateSignature";
import {useLocation} from "react-router-dom";
import {useStorageItem} from "@capacitor-community/storage-react";

const RecibirPaquete = props => {
  const {query} = useQuery();
  const history = useHistory();

  const [receptor, setReceptor] = useState('');
  const [drawn, setDrawn] = useState(false);
  const canvasRef = useRef();

  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);


  const match = matchPath(history.location.pathname, {
    path: '/recibir_paquete/:id',
    exact: true,
    strict: false
  });


  const handleSubmit = async () => {
    setLoading(true);

    const {error, data} = await query(`/api2/paquetes/${match.params.id}`, {
      receptor, firma: canvasRef.current.toDataURL()
    }, 'PUT');
    setLoading(false);

    if (error || !data.success) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    history.replace('/paqueteria');
  }


  return (
    <IonPage>
      <Header title="Recibir paquete"/>
      <IonContent fullscreen className="ion-padding">
        <IonLoading message="Cargando..." duration={0} isOpen={loading}/>
        <IonAlert
          isOpen={isError}
          onDidDismiss={() => setIsError(false)}
          header={"Error!"}
          message={message}
          buttons={["Aceptar"]}
        />
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel>Receptor</IonLabel>
                <IonInput
                  value={receptor}
                  onIonChange={(e) => setReceptor(e.detail.value)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div style={{margin: '20px'}}>
          <IonLabel>Firma</IonLabel>
        </div>
        <CreateSignature ref={canvasRef} onDraw={() => setDrawn(true)}/>

        {receptor.length > 2 && drawn &&
        <IonButton style={{marginTop: '20px'}} expand="block" onClick={handleSubmit}>
          Guardar
        </IonButton>}

      </IonContent>
    </IonPage>
  );
};


export default RecibirPaquete;
