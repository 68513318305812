import React, {useState} from 'react';
import s from './Login.module.css';
import {
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonIcon,
  IonAlert,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading
} from "@ionic/react";
import { personCircle } from "ionicons/icons";
import {validateEmail, validatePassword} from "../../util/validar";
import {useStorageItem} from "@capacitor-community/storage-react";
import {useQuery} from "../../hooks/useQuery";
import {useHistory} from "react-router";

const Login = props => {
  const {query} = useQuery();
  const history = useHistory();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState();

  const [user, setUser] = useStorageItem('user', 'false');
  const [token, setToken] = useStorageItem('token', 'false');
  const [notification_token, setNotificationToken ] = useStorageItem('notification_token', '');



  const validarDatos = () => {
    if(!validateEmail(email)){
      setMessage('El email es inválido');
      setIsError(true);
      return;
    }
    if(!password || !validatePassword(password)){
      setMessage("La contraseña debe tener minimo 6 carácteres");
      setIsError(true);
      return;
    }

    return true;
  }

  const handleSubmit = async () => {
    if(!validarDatos())return;
    setLoading(true);

    const {error, data} = await query('/api2/login', {
      email, pw: password, notification_token
    }, 'POST');

    setLoading(false);

    if(error || !data.token){
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    setUser(data.type);
    setToken(data.token);
    history.replace('/home');
  }


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonLoading message="Cargando..." duration={0} isOpen={loading}/>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={isError}
                onDidDismiss={() => setIsError(false)}
                header={"Error!"}
                message={message}
                buttons={["Aceptar"]}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon
                style={{fontSize: "70px", color: "#0040ff"}}
                icon={personCircle}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  onIonChange={(e) => setEmail(e.detail.value)}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonItem>
                <IonLabel position="floating">Contraseña</IonLabel>
                <IonInput
                  type="password"
                  value={password}
                  onIonChange={(e) => setPassword(e.detail.value)}
                >
                </IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={handleSubmit} disabled={loading}>Login</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem routerLink="/register">
                <IonLabel>¿No tienes cuenta? Registrate</IonLabel>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem routerLink="/pw_reset">
                <IonLabel>¿Contraseña olvidada? Pedir nueva</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};


export default Login;
