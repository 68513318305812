import React, {useEffect, useState} from 'react';
import s from './NuevaReserva.module.css';
import {
  IonAlert, IonBackButton, IonButton, IonButtons,
  IonContent, IonDatetime,
  IonHeader, IonItem, IonItemSliding, IonLabel,
  IonLoading, IonMenuButton,
  IonPage, IonRadio, IonRadioGroup,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {useQuery} from "../../hooks/useQuery";
import {matchPath, useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import Header from "../Header";
import moment from "moment";
import dayjs from "dayjs";

const NuevaReserva = () => {
  const {query} = useQuery();
  const history = useHistory();
  const location = useLocation();

  const [date, setDate] = useState(new Date().toISOString());
  const [sessions, setSessions] = useState();
  const [session, setSession] = useState();

  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState();

  const match = matchPath(history.location.pathname, {
    path: '/reservas/:id',
    exact: true,
    strict: false
  });




  const handleSubmit = async () => {
    setLoading(true);

    const {error, data} = await query('/api2/reservas', {
      date, session, zona: match.params.id
    }, 'POST');

    setLoading(false);

    if (error || !data.success) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    history.replace('/reservas');
  }

  const loadSessions = async () => {
    if(!match)return;

    const {error, data} = await query('/api2/sessions', {
      id: match.params.id,
      date
    }, 'GET');

    setLoading(false);

    if (error || !Array.isArray(data.sessions)) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    setSessions(data.sessions);
    setSession(false);
  }


  useEffect(() => {
    if (!date) return;
    loadSessions();
  }, [date, location.pathname]);

  useEffect(() => {
    setDate(new Date().toISOString());
  }, [location.pathname]);


  return (
    <IonPage>
      <Header title="Nueva reserva"/>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonLoading message="Cargando..." duration={0} isOpen={loading}/>
        <IonAlert
          isOpen={isError}
          onDidDismiss={() => setIsError(false)}
          header={"Error!"}
          message={message}
          buttons={["Aceptar"]}
        />

        <IonDatetime

          locale="es-ES"

          presentation="date"
          onIonChange={e => setDate(e.detail.value)}
          value={date}
          firstDayOfWeek={1}
        />

        {Array.isArray(sessions) && sessions.length > 0 ? <IonRadioGroup onIonChange={e => setSession(e.detail.value)}>
          <IonItemSliding>
            {sessions.map((_session, i) => <IonItem key={i}>
              <IonRadio disabled={!_session.allowed} style={{marginRight: '20px'}} value={_session.text} checked={_session.text === session}  />
              <IonLabel>{_session.text}</IonLabel>
            </IonItem>)}
          </IonItemSliding>
        </IonRadioGroup> : <div>No hay sesiones disponibles</div>}

        {session && <IonButton style={{marginTop: '20px'}} expand="block" onClick={handleSubmit}>
          Reservar
        </IonButton>}

      </IonContent>
    </IonPage>
  );
};


export default NuevaReserva;
