import React, {useEffect, useState} from 'react';
import s from './NuevaIncidencia.module.css';
import {useQuery} from "../../hooks/useQuery";
import {matchPath, useHistory} from "react-router";
import {
	IonAlert, IonButton, IonButtons, IonCol,
	IonContent,
	IonGrid,
	IonHeader, IonInput, IonItem, IonLabel,
	IonLoading, IonMenuButton,
	IonPage,
	IonRow, IonSelect, IonSelectOption, IonTextarea,
	IonTitle,
	IonToolbar, useIonViewDidEnter
} from "@ionic/react";
import Header from "../Header";
import {useStorageItem} from "@capacitor-community/storage-react";

const NuevaIncidencia = () => {
	const {query} = useQuery();
	const history = useHistory();

	const [date, setDate] = useState(new Date().toISOString());
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [estado, setEstado] = useState('en curso');
	const [type, setType] = useState();
	const [location, setLocation] = useState();
	const [portal, setPortal] = useState();
	const [portal_location, setPortal_location] = useState();
	const [garaje, setGaraje] = useState();
	const [zonas_comunes, setZonas_comunes] = useState();
	const [prioridad, setPrioridad] = useState();
	const [user, setUser] = useStorageItem('user', 'false');

	const [isError, setIsError] = useState();
	const [message, setMessage] = useState();
	const [loading, setLoading] = useState(true);

	const match = matchPath(history.location.pathname, {
		path: '/nueva_incidencia/:id',
		exact: true,
		strict: false
	});


	const handleSubmit = async () => {
		setLoading(true);
		let error, data;

		let incidencia = {
			title, description,tipo: type, ubicacion: location, portal, portal_ubicacion: portal_location, garaje, zonas_comunes
		};

		if (match?.params?.id) {
			const r = await query(`/api2/incidencias/${match.params.id}`, incidencia, 'PUT');
			error = r.error;
			data = r.data;
		} else {
			const r = await query('/api2/incidencias', incidencia, 'POST');
			error = r.error;
			data = r.data;
		}
		setLoading(false);

		if (error || !data.success) {
			setMessage(error || 'Se ha producido un error desconocido');
			setIsError(true);
			return;
		}

		history.replace('/incidencias');
	}

	const cargarIncidencia = async () => {
		setLoading(true);
		if (match?.params?.id) {
			const {error, data} = await query(`/api2/incidencias/${match.params.id}`, false, 'GET');
			if (error || !data.incidencia) {
				setMessage(error || 'Se ha producido un error desconocido');
				setIsError(true);
			} else {
				setTitle(data.incidencia.title);
				setDescription(data.incidencia.description);
				//setEstado(data.incidencia.state);
				if(data.incidencia.tipo)setType(data.incidencia.tipo);
				if(data.incidencia.ubicacion)setLocation(data.incidencia.ubicacion);
				if(data.incidencia.portal)setPortal(data.incidencia.portal);
				if(data.incidencia.portal_ubicacion)setPortal_location(data.incidencia.portal_ubicacion);
				if(data.incidencia.garaje)setGaraje(data.incidencia.garaje);
				if(data.incidencia.zonas_comunes)setZonas_comunes(data.incidencia.zonas_comunes);
				if(data.incidencia.prioridad)setPrioridad(data.incidencia.prioridad);
			}
		}
		setLoading(false);
	}

	useEffect(() => {
		cargarIncidencia();
	}, []);

	useIonViewDidEnter(() => {
		cargarIncidencia();
	}, []);


	return (
		<IonPage>
			<Header title="Nueva incidencia"/>
			<IonContent fullscreen className="ion-padding ion-text-center">
				<IonLoading message="Cargando..." duration={0} isOpen={loading}/>
				<IonAlert
					isOpen={isError}
					onDidDismiss={() => setIsError(false)}
					header={"Error!"}
					message={message}
					buttons={["Aceptar"]}
				/>
				<IonGrid>
					<IonRow>
						<IonCol>
							<IonItem>
								<IonLabel>Tipo</IonLabel>
								<IonSelect value={type} placeholder="Seleccione el tipo"
													 onIonChange={e => setType(e.detail.value)}>
									<IonSelectOption value="Albañilería">Albañilería</IonSelectOption>
									<IonSelectOption value="Cerrajería">Cerrajería</IonSelectOption>
									<IonSelectOption value="Electricidad e Iluminación">Electricidad e Iluminación</IonSelectOption>
									<IonSelectOption value="Fontanería">Fontanería</IonSelectOption>
									<IonSelectOption value="Limpieza">Limpieza</IonSelectOption>
									<IonSelectOption value="Pintura">Pintura</IonSelectOption>
									<IonSelectOption value="Otros">Otros</IonSelectOption>
								</IonSelect>
							</IonItem>
						</IonCol>
						<IonCol>
							<IonItem>
								<IonLabel>Ubicación</IonLabel>
								<IonSelect value={location} placeholder="Seleccione la ubicación"
													 onIonChange={e => setLocation(e.detail.value)}>
									<IonSelectOption value="Mi vivienda">Mi vivienda</IonSelectOption>
									<IonSelectOption value="Portal">Portal</IonSelectOption>
									<IonSelectOption value="Garaje">Garaje</IonSelectOption>
									<IonSelectOption value="Zonas Comunes">Zonas Comunes</IonSelectOption>
									<IonSelectOption value="Otros">Otros</IonSelectOption>
								</IonSelect>
							</IonItem>
						</IonCol>
					</IonRow>
					{location === 'Portal' && <IonRow>
						<IonCol>
							<IonItem>
								<IonLabel>Portal</IonLabel>
								<IonSelect value={portal} placeholder="Seleccione el portal"
													 onIonChange={e => setPortal(e.detail.value)}>
									<IonSelectOption value="Portal A">Portal A</IonSelectOption>
									<IonSelectOption value="Portal B">Portal B</IonSelectOption>
									<IonSelectOption value="Portal C">Portal C</IonSelectOption>
									<IonSelectOption value="Portal D">Portal D</IonSelectOption>
									<IonSelectOption value="Portal E">Portal E</IonSelectOption>
									<IonSelectOption value="Portal F">Portal F</IonSelectOption>
									<IonSelectOption value="Portal G">Portal G</IonSelectOption>
									<IonSelectOption value="Portal H">Portal H</IonSelectOption>
									<IonSelectOption value="Portal I">Portal I</IonSelectOption>
									<IonSelectOption value="Portal J">Portal J</IonSelectOption>
									<IonSelectOption value="Portal K">Portal K</IonSelectOption>
								</IonSelect>
							</IonItem>
						</IonCol>

						<IonCol>
							<IonItem>
								<IonLabel>Portal Ubicación</IonLabel>
								<IonSelect value={portal_location} placeholder="Seleccione el portal"
													 onIonChange={e => setPortal_location(e.detail.value)}>
									<IonSelectOption value="Entrada">Entrada</IonSelectOption>
									<IonSelectOption value="Rellanos">Rellanos</IonSelectOption>
									<IonSelectOption value="Escaleras">Escaleras</IonSelectOption>
									<IonSelectOption value="Trasteros">Trasteros</IonSelectOption>
									<IonSelectOption value="Cuarto basuras">Cuarto basuras</IonSelectOption>
									<IonSelectOption value="Otros">Otros</IonSelectOption>
								</IonSelect>
							</IonItem>
						</IonCol>
					</IonRow>}
					{location === 'Garaje' && <IonRow>
						<IonCol>
							<IonItem>
								<IonLabel>Garaje</IonLabel>
								<IonSelect value={garaje} placeholder="Seleccione el garaje"
													 onIonChange={e => setGaraje(e.detail.value)}>
									<IonSelectOption value="Garaje A-1">Garaje A-1</IonSelectOption>
									<IonSelectOption value="Garaje A-2">Garaje A-2</IonSelectOption>
									<IonSelectOption value="Garaje B-1">Garaje B-1</IonSelectOption>
									<IonSelectOption value="Garaje B-2">Garaje B-2</IonSelectOption>
								</IonSelect>
							</IonItem>
						</IonCol>
					</IonRow>}

					{location === 'Zonas Comunes' && <IonRow>
						<IonCol>
							<IonItem>
								<IonLabel>Zonas Comunes</IonLabel>
								<IonSelect value={zonas_comunes} placeholder="Seleccione la zona común"
													 onIonChange={e => setZonas_comunes(e.detail.value)}>
									<IonSelectOption value="Jardines">Jardines</IonSelectOption>
									<IonSelectOption value="Piscina">Piscina</IonSelectOption>
									<IonSelectOption value="Pistal de Pádel">Pistal de Pádel</IonSelectOption>
									<IonSelectOption value="Sala">Sala</IonSelectOption>
									<IonSelectOption value="Soportales">Soportales</IonSelectOption>
									<IonSelectOption value="Garita">Garita</IonSelectOption>
									<IonSelectOption value="Instalaciones">Instalaciones</IonSelectOption>
									<IonSelectOption value="Otros">Otros</IonSelectOption>
								</IonSelect>
							</IonItem>
						</IonCol>
					</IonRow>}

					<IonRow>
						<IonCol>
							<IonItem>
								<IonLabel>Título</IonLabel>
								<IonInput
									value={title}
									onIonChange={(e) => setTitle(e.detail.value)}
								/>
							</IonItem>
						</IonCol>
					</IonRow>
					<IonRow>
						<IonCol>
							<IonItem>
								<IonLabel position="floating">Descripción</IonLabel>
								<IonTextarea
									value={description}
									onIonChange={(e) => setDescription(e.detail.value)}
								/>
							</IonItem>
						</IonCol>
					</IonRow>
					{user !== 'vecino' &&
						<IonRow>
							<IonCol>
								<IonItem>
									<IonLabel>Estado</IonLabel>
									<IonSelect value={estado} placeholder="Seleccione el estado"
														 onIonChange={e => setEstado(e.detail.value)}>
										<IonSelectOption value="en curso">En curso</IonSelectOption>
										<IonSelectOption value="resuelta">Resuelta</IonSelectOption>
									</IonSelect>
								</IonItem>
							</IonCol>
						</IonRow>
					}
					{user !== 'vecino' &&
						<IonRow>
							<IonCol>
								<IonItem>
									<IonLabel>Prioridad</IonLabel>
									<IonSelect value={prioridad} placeholder="Seleccione la prioridad"
														 onIonChange={e => setPrioridad(e.detail.value)}>
										<IonSelectOption value="Alta">Alta</IonSelectOption>
										<IonSelectOption value="Media">Media</IonSelectOption>
										<IonSelectOption value="Baja">Baja</IonSelectOption>
									</IonSelect>
								</IonItem>
							</IonCol>
						</IonRow>
					}

				</IonGrid>

				{title.length > 2 && description.length > 5 &&
					<IonButton style={{marginTop: '20px'}} expand="block" onClick={handleSubmit}>
						Guardar
					</IonButton>}

			</IonContent>
		</IonPage>
	);
};


export default NuevaIncidencia;
