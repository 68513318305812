import React, {useEffect, useState} from 'react';
import s from './PasswordReset.module.css';
import {
  IonAlert, IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon, IonInput, IonItem, IonLabel,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import {personCircle} from "ionicons/icons";
import {useQuery} from "../../hooks/useQuery";
import {validateEmail} from "../../util/validar";
import {useLocation} from "react-router-dom";

const PasswordReset = props => {
  const {query} = useQuery();
  const location = useLocation();

  const [email, setEmail] = useState();
  const [isError, setIsError] = useState();
  const [message, setMessage] = useState();
  const [done, setDone] = useState(false);


  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setMessage('El email es inválido');
      setIsError(true);
      return;
    }

    const {error, data} = await query('/api2/user_pw', {email}, 'POST');

    if (error) {
      setMessage(error || 'Se ha producido un error desconocido');
      setIsError(true);
      return;
    }

    setDone(true);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Restablecer contraseña</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonAlert
                isOpen={isError}
                onDidDismiss={() => setIsError(false)}
                header={"Error!"}
                message={message}
                buttons={["Aceptar"]}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonIcon
                style={{fontSize: "70px", color: "#0040ff"}}
                icon={personCircle}
              />
            </IonCol>
          </IonRow>
          {done ?
            <IonRow>
              <IonCol>
                Gracias! Revise su correo electronico
              </IonCol>
            </IonRow>
            : <>
              <IonRow>
                <IonCol>
                  <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                      type="email"
                      value={email}
                      onIonChange={(e) => setEmail(e.detail.value)}
                    >
                    </IonInput>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton expand="block" onClick={handleSubmit}>Restablecer contraseña</IonButton>
                </IonCol>
              </IonRow>
              <IonRow>

                <IonCol>
                  <IonItem routerLink="/login">
                    <IonLabel>¿Ya tienes cuenta? Entrar</IonLabel>
                  </IonItem>
                </IonCol>
              </IonRow>
            </>
          }
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};


export default PasswordReset;
