import React, {useEffect, useImperativeHandle, useState} from 'react';
import s from './CreateSignature.module.css';
import {createGesture, IonItem, useIonViewDidEnter} from "@ionic/react";

const CreateSignature = React.forwardRef(({onDraw}, forwardRef) => {

  let canvasRef = React.useRef();
  useImperativeHandle(forwardRef, () => canvasRef.current);



  useEffect(() => {
    const gesture = createGesture({
      el: canvasRef?.current?.parentElement,
      threshold: 0,
      gestureName: 'signature',
      onMove: e => onMoveHandler(e),
      onStart: e => {
        console.log('start');
        let pos = getPos(e);
        let c = canvasRef.current.getContext('2d');
        c.beginPath();
        c.moveTo(pos.x, pos.y);
      },
      onEnd: e => {
        console.log('end');
        let c = canvasRef.current.getContext('2d');
        //c.closePath();
      }
    });
    gesture.enable();




/*    var gradient = c.createLinearGradient(0, 0, 170, 0);
    gradient.addColorStop("0", "#000");
    gradient.addColorStop("1.0", "#ccc");
    c.strokeStyle = gradient;*/

  }, [canvasRef.current]);


  useIonViewDidEnter(() => {
    if(canvasRef.current){
      let w = canvasRef.current.parentElement.offsetWidth;
      let h = canvasRef.current.parentElement.offsetHeight;
      console.log(h);
      canvasRef.current.style.width = w + 'px';
      canvasRef.current.style.height = h + 'px';

      canvasRef.current.setAttribute('width',w + 'px');
      canvasRef.current.setAttribute('height',h + 'px');
      let c = canvasRef.current.getContext('2d');
      c.lineWidth = 2;

    }
  }, []);



  const getPos = e => {
    var rect = canvasRef.current.parentElement.getBoundingClientRect();

    return {
      x: e.currentX - rect.left,
      y: e.currentY - rect.top
    }
  }



  const onMoveHandler = e => {
    //console.log(e);


    let c = canvasRef.current.getContext('2d');
    let pos = getPos(e);
    //console.log(pos);

    c.lineTo(pos.x, pos.y);
    c.stroke();
    onDraw();

    //console.log(rect.top, rect.right, rect.bottom, rect.left);
  }


  return (
    <div className={s.signatureContainer}>
      <canvas ref={canvasRef}/>
    </div>
  );
});


export default CreateSignature;
